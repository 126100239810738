import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

function HubSpotConnect({ onConnect }) {
  const [installUrl, setInstallUrl] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    fetchInstallUrl();
  }, []);

  const fetchInstallUrl = async () => {
    try {
      console.log('Fetching install URL for user:', user.id);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/hubspot-install-url/${user.id}`);
      console.log('Received install URL:', response.data.installUrl);
      setInstallUrl(response.data.installUrl);
    } catch (error) {
      console.error('Failed to fetch HubSpot install URL:', error);
      setError('Failed to fetch HubSpot install URL');
    }
  };

  return (
    <div>
      <h4>Connect Another HubSpot Portal</h4>
      {message && <p className="success">{message}</p>}
      {error && <p className="error">{error}</p>}
      {installUrl && (
        <a href={installUrl} target="_blank" rel="noopener noreferrer">
          <button>Connect to HubSpot</button>
        </a>
      )}
    </div>
  );
}

export default HubSpotConnect;
