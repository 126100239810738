import React, { createContext, useState, useContext, useEffect } from 'react';



const AuthContext = createContext();



export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(true);



  useEffect(() => {

    const token = localStorage.getItem('token');

    if (token) {

      // TODO: Implement token verification with the backend

      setUser(JSON.parse(localStorage.getItem('user')));

    }

    setLoading(false);

    console.log('AuthContext initialized');

  }, []);



  const login = (userData, token) => {

    setUser(userData);

    localStorage.setItem('token', token);

    localStorage.setItem('user', JSON.stringify(userData));

    console.log('User logged in:', userData);

  };



  const logout = () => {

    setUser(null);

    localStorage.removeItem('token');

    localStorage.removeItem('user');

    console.log('User logged out');

  };



  return (

    <AuthContext.Provider value={{ user, login, logout, loading }}>

      {children}

    </AuthContext.Provider>

  );

};



export const useAuth = () => useContext(AuthContext);


