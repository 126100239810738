import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';

import axios from 'axios';



function Register() {

  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');

  const [error, setError] = useState('');

  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  const { login } = useAuth();



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/register`, { email, password });
      login(response.data.user, response.data.token);
      setMessage(`Registration successful! You've been awarded ${response.data.user.credits} free credits.`);
      setTimeout(() => navigate('/dashboard'), 3000);
    } catch (error) {
      setError('Registration failed: ' + (error.response?.data?.error || error.message));
    }
  };



  return (

    <div>

      <h2>Register</h2>

      {error && <p className="error">{error}</p>}

      {message && <p className="success">{message}</p>}

      <form onSubmit={handleSubmit}>

        <input

          type="email"

          placeholder="Email"

          value={email}

          onChange={(e) => setEmail(e.target.value)}

          required

        />

        <input

          type="password"

          placeholder="Password"

          value={password}

          onChange={(e) => setPassword(e.target.value)}

          required

        />

        <button type="submit">Register</button>

      </form>

    </div>

  );

}



export default Register;



