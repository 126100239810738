import React from 'react';
import './LegalPages.css';

function PrivacyPolicy() {
  return (
    <div className="legal-container">
  <h1>Privacy Policy</h1>
  <p>Last updated: 10/22/2024</p>

  <section>
    <h2>1. Information We Collect</h2>
    <p>We collect your email address and password when you create an account. Passwords are stored in a hashed format. We also store your HubSpot portal information and your preferences for mapping City, State, County, and Country fields in HubSpot.</p>
  </section>

  <section>
    <h2>2. How We Use Your Information</h2>
    <p>Your email address is used for account login, communication, and transactional updates (e.g., password resets, account credits). We do not use your information for marketing purposes or sell your data to third parties.</p>
  </section>

  <section>
    <h2>3. Third-Party Services</h2>
    <p>We use third-party services to manage payments (Stripe) and email communications (SendGrid and Zoho Mail). These services have their own privacy policies, and we recommend reviewing them.</p>
  </section>

  <section>
    <h2>4. Cookies</h2>
    <p>We use cookies to handle login sessions and manage payments through Stripe. You can disable cookies in your browser settings, but doing so may impact your ability to use our site.</p>
  </section>

  <section>
    <h2>5. Data Retention</h2>
    <p>We retain your data as long as you are using our services. If you wish to delete your account and associated data, please contact us at info@zipcodeautofill.com, and we will fulfill your request.</p>
  </section>

  <section>
    <h2>6. Data Security</h2>
    <p>We use industry-standard security measures to protect your information. Passwords are hashed, and payment data is managed securely by Stripe.</p>
  </section>

  <section>
    <h2>7. Your Rights</h2>
    <p>You have the right to request access to or deletion of your personal information. To do so, please contact us at info@zipcodeautofill.com.</p>
  </section>

  <section>
    <h2>8. Changes to the Privacy Policy</h2>
    <p>We may update this Privacy Policy as necessary. You will be notified of significant changes via email or on our website.</p>
  </section>

  <section>
    <h2>9. Contact Us</h2>
    <p>If you have any questions regarding this Privacy Policy, please contact us at info@zipcodeautofill.com.</p>
  </section>
</div>

  );
}

export default PrivacyPolicy;
