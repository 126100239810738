import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Support.css';

function Support() {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    // Load the reCAPTCHA v3 script
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');
    
    try {
      // Execute reCAPTCHA with the site key
      const token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: 'submit'});

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/support/send-email`, {
        email,
        phone,
        subject,
        message,
        recaptchaToken: token
      });
      setStatus(response.data.message);
      setEmail('');
      setPhone('');
      setSubject('');
      setMessage('');
    } catch (error) {
      console.error('Support email error:', error);
      setStatus(error.response?.data?.error || 'Failed to send email. Please try again.');
    }
  };

  return (
    <div className="support-container">
      <div className="support-info">
        <h2>Need Help?</h2>
        <p>We're here to assist you with any questions or issues you may have. Please fill out the form to receive a quick response to your query.</p>
        <p>For real-time support and community discussions, join our Discord channel:</p>
        <a href="https://discord.gg/Cd8xFUK7wE" target="_blank" rel="noopener noreferrer" className="discord-link">
          Join our Discord Community
        </a>
      </div>
      <div className="support-form">
        <h2>Contact Support</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your Email Address"
            required
          />
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Your Phone Number"
            required
          />
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Subject"
            required
          />
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Your message"
            required
          ></textarea>
          <button type="submit">Send</button>
        </form>
        {status && <p className={status.includes('success') ? 'success' : 'error'}>{status}</p>}
      </div>
    </div>
  );
}

export default Support;
