import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { loadStripe } from '@stripe/stripe-js';
import './Credits.css';

const stripePromise = loadStripe('pk_live_51PySXtBq6BngvjtVWinZJVVoy78lHcus1ZLsoNfFIGWujSYDGpUPuhfcqpw8EBfSagHTODAfoznkQMEpTsouv90F00t5WRoTKD');

function Credits() {
  const { user } = useAuth();
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const creditPlans = [
    { credits: 1000, price: 25, savings: '0%' },
    { credits: 5000, price: 115, savings: '8%' },
    { credits: 10000, price: 225, savings: '10%' },
    { credits: 25000, price: 500, savings: '20%' },
  ];

  const handlePurchase = async (credits, price) => {
    try {
      const stripe = await stripePromise;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/create-checkout-session`, {
        userId: user.id,
        credits,
        price
      });
      
      const result = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });

      if (result.error) {
        setError(result.error.message);
      }
    } catch (error) {
      setError('Failed to initiate purchase');
    }
  };

  return (
    <div className="credits-container">
      <h1 className="credits-title">Purchase Credits</h1>
      <p className="credits-intro">
        Choose a credit package that suits your needs. The more credits you buy, the more you save!
      </p>
      {message && <p className="success">{message}</p>}
      {error && <p className="error">{error}</p>}
      <div className="credit-plans">
        {creditPlans.map((plan, index) => (
          <div key={index} className={`credit-plan ${index === 1 ? 'featured' : ''}`}>
            <h2>{plan.credits.toLocaleString()} Credits</h2>
            <p className="price">${plan.price}</p>
            <p className="savings">Save {plan.savings}</p>
            <ul>
              <li>${(plan.price / plan.credits).toFixed(3)} per credit</li>
              <li>No expiration</li>
              <li>Use for any HubSpot portal</li>
            </ul>
            <button onClick={() => handlePurchase(plan.credits, plan.price)} className="purchase-button">
              Purchase
            </button>
          </div>
        ))}
      </div>
      <div className="credits-faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-item">
          <h3>What types of postal codes are supported?</h3>
          <p>ZipCode AutoFill is specifically designed for 5-digit US ZIP codes. We do not support international postal codes or ZIP+4 codes. Each lookup of a valid 5-digit US ZIP code consumes one credit.</p>
        </div>
      </div>
    </div>
  );
}

export default Credits;
