import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

function PortalConfiguration() {
  const { portalId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [contactProperties, setContactProperties] = useState([]);
  const [companyProperties, setCompanyProperties] = useState([]);
  const [config, setConfig] = useState({
    contact: { city: '', state: '', county: '', country: '' },
    company: { city: '', state: '', county: '', country: '' }
  });

  useEffect(() => {
    fetchProperties();
    fetchExistingConfig();
  }, [portalId]);

  const fetchProperties = async () => {
    try {
      const contactResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/hubspot/contact-properties/${portalId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      const companyResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/hubspot/company-properties/${portalId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      setContactProperties(contactResponse.data);
      setCompanyProperties(companyResponse.data);
    } catch (error) {
      console.error('Error fetching properties:', error.response ? error.response.data : error.message);
      setError(`Failed to fetch properties: ${error.response ? error.response.data.error : error.message}`);
    }
  };

  const fetchExistingConfig = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/hubspot/portal-config/${portalId}`);
      setConfig(response.data);
    } catch (error) {
      console.error('Failed to fetch existing config:', error);
    }
    setLoading(false);
  };

  const handleConfigChange = (section, field, value) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      [section]: {
        ...prevConfig[section],
        [field]: value
      }
    }));
  };

  const saveConfig = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/hubspot/save-portal-config/${portalId}`, config);
      navigate('/dashboard');
    } catch (error) {
      setError('Failed to save configuration');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="card">
      <h2>Portal Configuration</h2>
      <h3 style={{ color: 'red' }}><b><u>Ensure that you choose single-line text or multi-line text properties!</u></b></h3>
      <h3>Contact Settings</h3>
      {['city', 'state', 'county', 'country'].map(field => (
        <div key={`contact-${field}`}>
          <label>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
          <select
            value={config.contact[field]}
            onChange={(e) => handleConfigChange('contact', field, e.target.value)}
          >
            <option value="">Do Not Update with Location Data</option>
            {contactProperties.map(prop => (
              <option key={prop.name} value={prop.name}>{prop.label}</option>
            ))}
          </select>
        </div>
      ))}
      <h3>Company Settings</h3>
      {['city', 'state', 'county', 'country'].map(field => (
        <div key={`company-${field}`}>
          <label>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
          <select
            value={config.company[field]}
            onChange={(e) => handleConfigChange('company', field, e.target.value)}
          >
            <option value="">Do Not Update with Location Data</option>
            {companyProperties.map(prop => (
              <option key={prop.name} value={prop.name}>{prop.label}</option>
            ))}
          </select>
        </div>
      ))}
      <br />
      <button onClick={saveConfig}>Save Configuration</button>
    </div>
  );
}

export default PortalConfiguration;
