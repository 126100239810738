import React from 'react';
import { Link } from 'react-router-dom';

function Pricing() {
  return (
    <div className="pricing-container">
      <h1 className="pricing-title">Simple, Transparent Pricing</h1>
      <p className="pricing-intro">
        ZipCode AutoFill offers a flexible, pay-as-you-go model. Purchase credits and use them as you need, with no monthly fees or commitments.
      </p>
      
      <div className="pricing-grid">
        <div className="pricing-plan">
          <h2>Starter Pack</h2>
          <p className="price">$25</p>
          <p className="credits">1,000 Credits</p>
          <ul>
            <li>Perfect for small businesses</li>
            <li>2.5¢ per lookup</li>
            <li>No expiration on credits</li>
          </ul>
          <Link to="/register" className="cta-button">Get Started</Link>
        </div>
        
        <div className="pricing-plan featured">
          <h2>Growth Pack</h2>
          <p className="price">$115</p>
          <p className="credits">5,000 Credits</p>
          <ul>
            <li>Ideal for growing companies</li>
            <li>2.3¢ per lookup</li>
            <li>8% savings</li>
          </ul>
          <Link to="/register" className="cta-button">Get Started</Link>
        </div>
        
        <div className="pricing-plan">
          <h2>Scale Pack</h2>
          <p className="price">$225</p>
          <p className="credits">10,000 Credits</p>
          <ul>
            <li>Best for high-volume users</li>
            <li>2.25¢ per lookup</li>
            <li>10% savings</li>
          </ul>
          <Link to="/register" className="cta-button">Get Started</Link>
        </div>

        <div className="pricing-plan">
          <h2>Enterprise Pack</h2>
          <p className="price">$500</p>
          <p className="credits">25,000 Credits</p>
          <ul>
            <li>For large-scale operations</li>
            <li>2¢ per lookup</li>
            <li>20% savings</li>
          </ul>
          <Link to="/register" className="cta-button">Get Started</Link>
        </div>
      </div>
      
      <div className="pricing-faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-item">
          <h3>What is a credit?</h3>
          <p>One credit equals one ZIP code lookup and auto-fill operation. Each time you use our service to populate address fields from a ZIP code, one credit is deducted from your balance.</p>
        </div>
        <div className="faq-item">
          <h3>Do credits expire?</h3>
          <p>No, your purchased credits never expire. Use them at your own pace without worrying about time limits.</p>
        </div>
        <div className="faq-item">
          <h3>Can I purchase a custom amount of credits?</h3>
          <p>For large volume needs or custom pricing, please contact our <a href="mailto:info@zipcodeautofill.com">sales team.</a> We're happy to create a tailored plan for your business.</p>
        </div>
        <div className="faq-item">
          <h3>What types of ZIP codes does ZipCode AutoFill support?</h3>
          <p>ZipCode AutoFill is designed to work exclusively with 5-digit US ZIP codes. We do not support international postal codes or ZIP+4 codes at this time. (We won't charge you if you accidentally try)</p>
        </div>
        <div className="faq-item">
          <h3>Can I backfill existing contacts?</h3>
          <p><b>Yes!</b> We support backfilling location data on existing contact and company records that have a 5-digit US postal code, as long as you have access to HubSpot workflows. If you do not have access to HubSpot workflows, but would still like to backfill, please reach out to our <a href="mailto:info@zipcodeautofill.com">support team</a> for assistance.</p>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
