import React from 'react';
import './LegalPages.css';

function TermsOfService() {
  return (
    <div className="legal-container">
  <h1>Terms of Service</h1>
  <p>Last updated: 10/22/2024</p>

  <section>
    <h2>1. Acceptance of Terms</h2>
    <p>By accessing or using ZipCode AutoFill, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our service.</p>
  </section>

  <section>
    <h2>2. User Accounts</h2>
    <p>To use our service, you must create an account using a valid email address and a password. You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>
  </section>

  <section>
    <h2>3. Services Provided</h2>
    <p>ZipCode AutoFill connects to your HubSpot portal and automatically fills City, State, County, and Country fields based on the postal code entered into contact or company records. A valid HubSpot account and portal connection is required.</p>
  </section>

  <section>
    <h2>4. Payment</h2>
    <p>We offer a credit-based system for using our services, which are purchased via Stripe. All payments are handled securely, and we do not store your payment information. For issues regarding payments, refunds, or credits, please contact us at info@zipcodeautofill.com.</p>
  </section>

  <section>
    <h2>5. Cookies</h2>
    <p>We use cookies to manage login sessions and facilitate payment processing through Stripe. By using our site, you consent to the use of these cookies.</p>
  </section>

  <section>
    <h2>6. Refunds and Disputes</h2>
    <p>If you have any issues related to refunds, payments, or service use, please contact us at info@zipcodeautofill.com for resolution.</p>
  </section>

  <section>
    <h2>7. Termination</h2>
    <p>We reserve the right to suspend or terminate your account if we suspect a breach of these Terms or misuse of the service.</p>
  </section>

  <section>
    <h2>8. Changes to Terms</h2>
    <p>We reserve the right to modify these Terms at any time. We will notify you of significant changes via email or through the website.</p>
  </section>

  <section>
    <h2>9. Contact Us</h2>
    <p>If you have any questions regarding these Terms of Service, please contact us at info@zipcodeautofill.com.</p>
  </section>
</div>

  );
}

export default TermsOfService;
