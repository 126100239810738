import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import HubSpotConnect from './HubSpotConnect';
import { useLocation, Link } from 'react-router-dom';

function Dashboard() {
  const { user } = useAuth();
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [creditBalance, setCreditBalance] = useState(0);
  const [hubspotConnections, setHubspotConnections] = useState([]);
  const location = useLocation();

  useEffect(() => {
    fetchCreditBalance();
    fetchHubSpotConnections();

    // Check for HubSpot connection success/failure
    const params = new URLSearchParams(location.search);
    const hubspotConnected = params.get('hubspot_connected');
    if (hubspotConnected === 'true') {
      setMessage('HubSpot connected successfully!');
      fetchHubSpotConnections();
    } else if (hubspotConnected === 'false') {
      setError('Failed to connect HubSpot. Please try again.');
    }

    // Check for successful payment
    const sessionId = params.get('session_id');
    if (sessionId) {
      setMessage('Payment successful! Your credits have been added to your account.');
      fetchCreditBalance();
    }
  }, [user.id, location]);

  const fetchCreditBalance = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/credit-balance/${user.id}`);
      setCreditBalance(response.data.creditBalance);
    } catch (error) {
      console.error('Failed to fetch credit balance:', error);
    }
  };

  const fetchHubSpotConnections = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/hubspot-connections/${user.id}`);
      setHubspotConnections(response.data.connections);
    } catch (error) {
      console.error('Failed to fetch HubSpot connections:', error);
    }
  };

  const deleteHubSpotConnection = async (connectionId) => {
    if (window.confirm('Are you sure you want to delete this HubSpot connection?')) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/auth/hubspot-connection/${connectionId}`);
        console.log('Delete response:', response.data);
        setMessage('HubSpot connection deleted successfully');
        fetchHubSpotConnections(); // Refresh the connections list
      } catch (error) {
        console.error('Failed to delete HubSpot connection:', error.response ? error.response.data : error.message);
        setError(`Failed to delete HubSpot connection: ${error.response ? error.response.data.error : error.message}`);
      }
    }
  };

  const refreshCreditBalance = () => {
    fetchCreditBalance();
    setMessage('Credit balance refreshed');
  };

  return (
    <div className="dash-container">
      <h2>Dashboard</h2>
      <p>Welcome, {user.email}!</p>
      
      <div className="card">
        <h3>Credit Balance</h3>
        <p>You have {creditBalance} credits available.</p>
        <button onClick={refreshCreditBalance}>Refresh Balance</button>
      </div>

      <div className="card">
        <h3>HubSpot Connections</h3>
        {hubspotConnections.length > 0 ? (
          <ul className="hubspot-connections">
            {hubspotConnections.map((connection) => (
              <li key={connection.id}>
                <span>Portal ID: {connection.portal_id}</span>
                <span>Connected on: {new Date(connection.created_at).toLocaleString()}</span>
                <Link to={`/configure-portal/${connection.portal_id}`}>
                  <button>Configure</button>
                </Link>
                <button onClick={() => deleteHubSpotConnection(connection.id)}>Delete</button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No HubSpot portals connected.</p>
        )}
        <HubSpotConnect onConnect={fetchHubSpotConnections} />
      </div>

      {message && <p className="success">{message}</p>}
      {error && <p className="error">{error}</p>}
    </div>
  );
}

export default Dashboard;
